import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import "react-bootstrap";
import "../App.css";
import MCQuestions from "./ManagerCompanyQuestions";

//Add register
interface ManagerCompanyQuestionsProps {
    company: string | undefined;
    register: any;
}

const MCAnswers = [
    "Almost Always",
    "Sometimes",
    "Never"
]

//Pass register
function ManagerCompanyQuestions({
    company,
    register,
}: ManagerCompanyQuestionsProps) {

    return (
        <Container>
            <Row className=" pb-5">
                <Col>
                    <h3 className="text-cerulean">
                        Please answer the following questions below on what you thought of
                        your manager and/or the company as a whole:
                    </h3>
                </Col>
            </Row>
            <Row>
                <MCQuestions company={company} register={register} answers={MCAnswers} title={'Demonstrated fair and equal treatment?'} questionID={'mc_q1'} questionID2={'mc_q2'} />
                <MCQuestions company={company} register={register} answers={MCAnswers} title={'Provided recognition for work done well?'} questionID={'mc_q3'} questionID2={'mc_q4'} />

            </Row>

            <Row className="pb-5">
                <Col>
                    <label className="col-form-label text-cerulean pb-2">
                        Comments
                    </label>

                    <div className="col-sm-10">
                        <textarea
                            {...register("mc_q13")}
                            className="form-control"

                        >
                        </textarea>
                    </div>
                </Col>
                <Col>
                    <label className="col-form-label text-cerulean pb-2">
                        Comments
                    </label>

                    <div className="col-sm-10">
                        <textarea
                            {...register("mc_q14")}
                            className="form-control"
                        >
                        </textarea>
                    </div>
                </Col>

            </Row>

            <Row>
                <MCQuestions company={company} register={register} answers={MCAnswers} title={'Fostered an inclusive environment?'} questionID={'mc_q5'} questionID2={'mc_q6'} />
                <MCQuestions company={company} register={register} answers={MCAnswers} title={'Encouraged and listened to suggestions?'} questionID={'mc_q7'} questionID2={'mc_q8'} />
            </Row>
            <Row className="pb-5">
                <Col>
                    <label className="col-form-label text-cerulean pb-2">
                        Comments
                    </label>

                    <div className="col-sm-10">
                        <textarea
                            {...register("mc_q15")}
                            className="form-control"

                        >
                        </textarea>
                    </div>
                </Col>
                <Col>
                    <label className="col-form-label text-cerulean pb-2">
                        Comments
                    </label>

                    <div className="col-sm-10">
                        <textarea
                            {...register("mc_q16")}
                            className="form-control"
                        >
                        </textarea>
                    </div>
                </Col>

            </Row>

            <Row>
                <MCQuestions company={company} register={register} answers={MCAnswers} title={'Followed policies and procedures?'} questionID={'mc_q9'} questionID2={'mc_q10'} />
                <MCQuestions company={company} register={register} answers={MCAnswers} title={'Resolved complaints and problems?'} questionID={'mc_q11'} questionID2={'mc_q12'} />
            </Row>

            <Row className="pb-5">
                <Col>
                    <label className="col-form-label text-cerulean pb-2">
                        Comments
                    </label>

                    <div className="col-sm-10">
                        <textarea
                            {...register("mc_q17")}
                            className="form-control"

                        >
                        </textarea>
                    </div>
                </Col>
                <Col>
                    <label className="col-form-label text-cerulean pb-2">
                        Comments
                    </label>

                    <div className="col-sm-10">
                        <textarea
                            {...register("mc_q18")}
                            className="form-control"
                        >
                        </textarea>
                    </div>
                </Col>

            </Row>
        </Container>
    );
}

export default ManagerCompanyQuestions;
