import { Container } from "react-bootstrap";
import "../index.css";

function AccessDenied() {
  return (
    <Container fluid className="bg-prussian-blue  ps-0">
      <div className="bg-prussian-blue vh-100 text-white text-center">
        <div
          className="d-flex align-items-end justify-content-center"
          style={{ height: "22em" }}
        >
          <h1 className="fs-2 mb-3">Access Denied</h1>
        </div>
        <div className="d-flex justify-content-center w-50%">
          <p style={{ width: "28em" }}>
            You currently do not have permission to access this page. Please
            contact <a href="mailto:hr@northwindgrp.com">hr@northwindgrp.com</a>{" "}
            with any questions.
          </p>
        </div>
      </div>
    </Container>
  );
}

export default AccessDenied;
