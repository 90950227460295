import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import RatingQuestions from "./RatingsQuestions";

interface PerformanceQuestionsProps { 
    register: any;
}
const ASN = [
    'Almost Always',
    'Sometimes',
    'Never'
]
const YN = [
    'Yes',
    'No'
]

function PerformanceQuestions({ 
    register,
}: PerformanceQuestionsProps) {

    return (
        <Container>

            <Row className="">
                <Col>
                    <h4 className="text-prussian-blue py-1 fw-600">
                        Performance Feedback
                    </h4>
                </Col>
            </Row>

            <Row className="pb-5">
                <RatingQuestions register={register} answers={ASN} title={'How frequently did you get performance feedback?'} questionID={'perf_q1'} />
                <RatingQuestions register={register} answers={YN} title={'In your opinion, was the performance feedback sufficient?'} questionID={'perf_q2'} />
            </Row>
            <Row className="mb-5">
                <RatingQuestions register={register} answers={ASN} title={'How frequently did you have discussions with your manager about your career goals?'} questionID={'perf_q3'} />
            </Row>

            <Row className="mb-4">
                <Col >
                    <label className="text-cerulean pb-3">
                        Comments:
                    </label>

                    <div className="col-sm-9">
                        <textarea
                            {...register("perf_q4")}
                            className="form-control"
                        >
                        </textarea>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
export default PerformanceQuestions;
