import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LoggedInUser, userContext } from '../Components/context/UserContext';
import EmployeeInformation from "../Components/EmployeeInformation";
import ExitForm from "../Components/ExitForm";
import Header from "../Components/Header";
import Intro from "../Components/Intro";
import ShowSearch from "../Utils/SearchBar";
import { Employee } from "../Interfaces/IFormInput";
import '../index.css';



const Home = () => {

    const history = useHistory();
    const currentUser = useContext(userContext);

    const { oktaAuth } = useOktaAuth();

    const [targetUser, setTargetUser] = useState<Employee>();
    const [isLoading, setIsLoading] = useState(true);


    const onChange = (event: any) => {
        console.log(event.target.value);
        if (event.target.value != null)
            setTargetUser(event.target.value);
    };

    const getEmployeeData = async (id: string) => {
        const accessToken = oktaAuth.getAccessToken();
        const bamboourl = process.env.REACT_APP_ENDPOINT_URL + `/api/bamboo/${id}`;
        try {
            await axios.get(bamboourl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            })
                .then((result: any) => {
                    setTargetUser(result.data);
                    checkIfUserAuthorized(result.data); 
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const checkIfSubmitted = async (info: Employee) => {

        const accessToken = oktaAuth.getAccessToken();
        const formurl = process.env.REACT_APP_ENDPOINT_URL + `/api/FormSubmission?id=${info.id}`;
        await axios.get(formurl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        })
            .then((result: any) => {
                if (result.data.status === 'submitted')
                    history.push("/thankyou");
            })
    }

    const checkIfUserAuthorized = async (user:any) => { 
        if (!(currentUser!.Department == "Human Resources" || currentUser!.Department == "Information Technology")) {  
            if (user?.terminationDate === "0000-00-00") {
                //history.push("/accessdenied");
            }
        }
    }
     
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            console.log(currentUser); 
            await getEmployeeData(currentUser!.bamboohrId);
                  
            setIsLoading(false);
        };

        fetchData();
    }, []);

    return (
        <>

            {(!isLoading && currentUser && targetUser) ?
                <>
                    <Header name={currentUser.name} />

                    <Container>
                        <Intro />

                        {(currentUser.Department == "Human Resources" || currentUser.Department == "Information Technology") &&
                            <Row className="py-4">
                                <ShowSearch onChange={onChange} />
                            </Row>
                        }

                    </Container>
                    {targetUser ?
                        <>

                            <EmployeeInformation targetUser={targetUser} />

                            <ExitForm targetUser={targetUser} />
                        </>
                        :
                        <div className="d-flex justify-content-center vh-100">
                            <div className="align-self-center">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>

                        </div>
                    }


                </>
                :
                <div className="d-flex justify-content-center vh-100">
                    <div className="align-self-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>

                </div>
            }
        </>
    );
};
export default Home;
