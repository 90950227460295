import { Container, Navbar } from "react-bootstrap"; 


interface HeaderProps {
    name: string | undefined; 
} 
 
const Header = ({ name }: HeaderProps) => {
    

    return (
        <Navbar className="bg-whisper m-0 p-1 d-flex" style={{height:'50px'}}>
            <Container fluid className="d-flex" style={{ justifyContent: "end" }}>
                <>
                    {name && (
                        <span>Hello, {name}</span>
                    )}
                </>
            </Container>
        </Navbar>
    );
};

export default Header;
