
import React from 'react'
import { Col } from 'react-bootstrap';

interface MCQuestionsProps {
    company: string | undefined;
    register: any;
    answers: any
    title: string
    questionID: string
    questionID2: string
}

function MCQuestions({ company, register, answers, title, questionID, questionID2 }: MCQuestionsProps) {

    const MCAnswers = answers.map((item: string) => (
        <option className="dropdown-value" key={item} value={item}>
            {item}
        </option>
    ));

    return (
        <Col sm={12} md={6}>
            <label className="text-cerulean mb-3">
                {title}
            </label>
            <div className="mb-1 row">
                <label className="col-sm-4 col-form-label text-cerulean">
                    Manager
                </label>
                <div className="col-sm-8">
                    <select className="form-select w-75 " {...register(questionID)}>
                        <option hidden value="">Select One</option>
                        {MCAnswers}
                    </select>
                </div>
            </div>
            <div className="mb-1 row">
                <label className="col-sm-4 col-form-label text-cerulean">
                    {company}
                </label>

                <div className="col-sm-8">
                    <select className="form-select w-75 "  {...register(questionID2)}>
                        <option hidden value="">Select One</option>
                        {MCAnswers}
                    </select>
                </div>
            </div>

        </Col>
    )
}


export default MCQuestions;
