import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Employee } from "../Interfaces/IFormInput";

interface EmployeeInformationProps {
    targetUser: Employee; 
}
const EmployeeInformation = ({ targetUser }: EmployeeInformationProps) => {
    function supervisorNameOrder(fullname: string) {
        if (fullname != null) {
            let name = fullname.split(',')
            return name[1] + " " + name[0];
        }
        else {
            return fullname
        }
    }
     
return (
    <Container>
        <Row className="mb-5 text-start">
            <Col xs={12} className="mb-4">
                <h2 className="text-cerulean">Employee Information</h2>
            </Col>
            <Col sm={12} md={6}>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label text-cerulean">
                        Employee Name:
                    </label>
                    <label className="col-sm-6 col-form-label text-black">
                        {targetUser.fullName1}
                    </label>

                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label text-cerulean">
                        Job Title:
                    </label>
                    <label className="col-sm-6 col-form-label text-black">
                        {targetUser.jobTitle}
                    </label>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label text-cerulean">
                        Supervisor:
                    </label>
                    <label className="col-sm-6 col-form-label text-black">
                        {supervisorNameOrder(targetUser.supervisor)}
                    </label>
                </div>
                <div className="form-group row d-none">
                    <label className="col-sm-4 col-form-label text-cerulean">
                        Company:
                    </label>
                    <label className="col-sm-6 col-form-label text-black">
                        {targetUser.division}
                    </label>
                </div>
            </Col>
            <Col sm={12} md={6}>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label text-cerulean">
                        Employment Date:
                    </label>
                    <label className="col-sm-6 col-form-label text-black">
                        {targetUser.hireDate.toString() != "0000-00-00" &&
                            new Intl.DateTimeFormat("en-US", { month: "numeric", day: "2-digit", year: "numeric" }).format(
                                new Date(targetUser.hireDate + ' EST'))
                        }
                    </label>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label text-cerulean">
                        Location:
                    </label>
                    <label className="col-sm-6 col-form-label text-black">
                        {targetUser.location}
                    </label>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label text-cerulean">
                        Termination Date:
                    </label>
                    <label className="col-sm-6 col-form-label text-black">
                        {targetUser.terminationDate.toString() != "0000-00-00" &&
                            new Intl.DateTimeFormat("en-US", { month: "numeric", day: "2-digit", year: "numeric" }).format(
                                new Date(targetUser.terminationDate + ' EST'))
                        }
                    </label>
                </div>
                <div className="form-group row d-none">
                    <label className="col-sm-4 col-form-label text-cerulean">
                        Region:
                    </label>
                    <label className="col-sm-6 col-form-label text-black">
                        {targetUser.region}
                    </label>
                </div>
            </Col>

        </Row>
    </Container>
    )

}
export default EmployeeInformation; 