import { Container } from "react-bootstrap";

export default function ThankYou() {
  return (
    <Container fluid className="ps-0">
      <div className="vh-100 text-cerulean text-center">
        <div
          className="d-flex align-items-end justify-content-center"
          style={{ height: "22em" }}
        >
          <h1 className="fs-2 mb-3">Thank You</h1>
        </div>
        <div className="d-flex justify-content-center">
          <p style={{ width: "40em" }}>
            Your form has been submitted. Thank you for filling out the Exit
            Interview Questionnaire. We appreciate your feedback and we will
            review your comments and suggestions.
          </p>
        </div>
      </div>
    </Container>
  );
}
