import React from "react";
import { Row, Col } from "react-bootstrap";

const Intro = () => {
    return (
                        <Row className="p-5">
                            <Col sm={12}>
                                <h1 className="fw-bolder text-center text-prussian-blue mb-4">
                                    Exit Interview Questionnaire
                                </h1>

                                <p className="text-center text-cerulean">
                                    We would appreciate you taking the time to answer the following
                                    questions as honestly as possible. Your individual responses are
                                    treated as confidential. We believe that the information is of vital
                                    importance and will assist in analyzing the factors attributing to
                                    turnover. Thank you!
                                </p>
                            </Col>
                        </Row>
        )
}
export default Intro; 