import { useOktaAuth } from "@okta/okta-react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Employee, IFormInput } from "../Interfaces/IFormInput";
import { userContext } from "./context/UserContext";
import LongFormQuestions from "./LongFormQuestions";
import ManagerCompany from "./ManagerCompany";
import MultiSelect2 from "./MultiSelector";
import PDFOutput from "./PDFOutput";
import PerformanceQuestions from "./PerformanceQuestions";
import Ratings from "./Ratings";
import ReasonsForLeaving from "./ReasonsForLeaving";

interface ExitFormProps {
    targetUser: Employee
}

const ExitForm = ({ targetUser }: ExitFormProps) => {

    const history = useHistory();
    const currentUser = useContext(userContext);

    const { oktaAuth } = useOktaAuth();
    const { setValue, handleSubmit, register, watch, reset, getValues } = useForm<IFormInput>(
        {
            mode: 'onChange',
        } 
    );

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            onWatch(Date.now());
            console.log(value, name, type);
            return () => subscription.unsubscribe();
        })
    }, [watch]);

    const [saveButtonText, setSaveButtonText] = useState("Save");
    const [savingDisabled, setSavingDisabled] = useState(true);
    const [watchChange, onWatch] = useState<number>();

    const getFormDataAsync = async () => {
        const formurl = process.env.REACT_APP_ENDPOINT_URL + `/api/FormSubmission?id=${targetUser.id}`;
        const accessToken = oktaAuth.getAccessToken();
        await axios.get(formurl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        })
            .then((result: any) => {
                reset(result.data)
                setValue("currentUser", currentUser?.name)
                setValue("targetUser", targetUser)
                setValue("targetUserID", targetUser.id)

                if (result.data.perf_MultiSelect === ['']) {
                    setValue("perf_MultiSelect", ['']);
                }
                if (result.data.rfl_MultiSelect === ['']) {
                    setValue("rfl_MultiSelect", ['']);
                }
            })
    }

    useEffect(() => {
        const getFormData = async () => {
            try {
                getFormDataAsync();
            }
            catch (error) {
                console.log(error);
            }
        }
        getFormData();

    }, [targetUser.id])


    const disableSave = async () => {
        setSavingDisabled(false);
        setSaveButtonText("Saving...");
        console.log("Saving");
    }

    const enableSave = async () => {
        setSavingDisabled(true);
        setSaveButtonText("Save");
        console.log("4. Saved");
    }

    const postData = async (data: any) => {
        const submiturl = process.env.REACT_APP_ENDPOINT_URL + `/api/FormSubmission`;
        await axios.post(submiturl, data)
            .then(async (post) => {
                await enableSave();
                if (data.status === "submitted") {
                    history.push("/thankyou");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const generatePDF = async () => {
        let base = '';
        let gridElement = document.querySelector(".page") as any;
        await drawDOM(gridElement, { paperSize: "A4" })
            .then((group) => {
                return exportPDF(group);
            })
            .then((dataUri) => {
                base = dataUri.split(";base64,")[1] as string;
            });
        return base;
    }


    const onSubmit: SubmitHandler<IFormInput> = async (data, event) => {
        console.log("type");
        console.log(typeof data);

        const status = event?.target.value;

        await disableSave();


        const submitData = async () => {
            data.currentUser = currentUser?.name;
            data.targetUser = targetUser.fullName1;
            data.region = targetUser.region;
            data.status = status;
            if (status === "submitted") {
                data.pdfInfo = await generatePDF();
            }
            await postData(data);
        }

        await submitData();


    };


    return (
        <Container className="px-0">
            <form>

                <ReasonsForLeaving
                    company={targetUser.division}
                    register={register}
                    watch={watch}
                />

                <ManagerCompany
                    company={targetUser.division}
                    register={register}
                />

                <Ratings
                    company={targetUser.division}
                    register={register}
                    watch={watch}
                />

                <MultiSelect2
                    company={targetUser.division}
                    register={register}
                    watch={watch}
                />
                <PerformanceQuestions
                    register={register}
                />

                <LongFormQuestions
                    company={targetUser.division}
                    register={register}
                />
                <div className="container">
                    <div
                        className="d-grid gap-2 d-md-flex justify-content-md-end mb-4 mt-5"
                        style={{ marginRight: "210px" }}
                    >
                        <button className="btn btn-ghost" value='saved' disabled={!savingDisabled} onClick={handleSubmit(onSubmit)}>
                            {saveButtonText}
                        </button>
                        <button className="btn btn-cerulean" value='submitted' disabled={!savingDisabled} type="submit" onClick={handleSubmit(onSubmit)}>
                            Submit
                        </button>

                    </div>
                </div>
            </form>

            {watchChange &&
                <PDFOutput
                    targetUser={targetUser}
                    getValues={getValues}
                />
            }

        </Container>

    )
}

export default ExitForm;

