import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { ReasonsForLeaving, ManagerCompany, Ratings, MultiSelect2, Performance, LongFormQuestions } from '../Interfaces/IFormInput';
import { IFormInput, Employee } from "../Interfaces/IFormInput";
import { savePDF } from "@progress/kendo-react-pdf";
import { useForm, UseFormWatch, UseFormGetValues } from "react-hook-form";

interface Props {
    targetUser: Employee;
    getValues: UseFormGetValues<IFormInput>
}

const PDFOutput = ({ targetUser, getValues }: Props) => { 

    const onPreviewPDF = async () => { 
        let gridElement = document.querySelector(".page");
        savePDF(gridElement, {
            paperSize: ["8.5 in", "11 in"],
            margin: "1cm",
            keepTogether: ".container",
            scale: 0.6
        });
    }

    const parseArray = (id: string) => {
        const values: [] = getValues(id);
        if (values && values.length > 0) {
            return values.map((value) => {
                return <span key={value}>{value} </span>
            }
            )
        }
    }

    const supervisorNameOrder = (fullname: string) => {
        if (fullname != null) {
            let name = fullname.split(',')
            return name[1] + " " + name[0];
        }
        else {
            return fullname
        }
    }

    return (
        <>
            <div style={{
                position: "absolute",
                left: "-10000px",
                top: 0
            }}>
            <div className="page">
                <Container>
                    <Row className="mb-5 text-start">
                        <Col xs={12} className="mb-4">
                            <h2 className="text-cerulean">Employee Information</h2>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label text-cerulean">
                                    Employee Name:
                                </label>
                                <label className="col-sm-6 col-form-label text-black">
                                    {targetUser.fullName1}
                                </label>

                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label text-cerulean">
                                    Job Title:
                                </label>
                                <label className="col-sm-6 col-form-label text-black">
                                    {targetUser.jobTitle}
                                </label>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label text-cerulean">
                                    Supervisor:
                                </label>
                                <label className="col-sm-6 col-form-label text-black">
                                    {supervisorNameOrder(targetUser.supervisor)}
                                </label>
                            </div>
                        </Col>
                        <Col sm={12} md={6}>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label text-cerulean">
                                    Employment Date:
                                </label>
                                <label className="col-sm-6 col-form-label text-black">
                                    {targetUser.hireDate.toString() != "0000-00-00" &&
                                        new Intl.DateTimeFormat("en-US", { month: "numeric", day: "2-digit", year: "numeric" }).format(
                                            new Date(targetUser.hireDate + ' EST'))
                                    }
                                </label>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label text-cerulean">
                                    Location:
                                </label>
                                <label className="col-sm-6 col-form-label text-black">
                                    {targetUser.location}
                                </label>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label text-cerulean">
                                    Termination Date:
                                </label>
                                <label className="col-sm-6 col-form-label text-black">
                                    {targetUser.terminationDate.toString() != "0000-00-00" &&
                                        new Intl.DateTimeFormat("en-US", { month: "numeric", day: "2-digit", year: "numeric" }).format(
                                            new Date(targetUser.terminationDate + ' EST'))
                                    }
                                </label>
                            </div>
                        </Col>

                    </Row>
                </Container>
                <Container >
                    {ReasonsForLeaving.map((item) =>
                        <Row className="mb-5">
                            <Col sm={12}>
                                <div className="form-group">
                                    <p className=" text-cerulean">{item.question.replace("|theCompany|", targetUser.division)}</p>
                                    {item.type === 'checkbox' ?
                                        parseArray(item.id)
                                        :
                                        <label key={item.id}>{getValues(item.id)}</label>
                                    }
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
                <Container >
                    <Row className=" pb-5">
                        <Col>
                            <h3 className="text-cerulean">
                                Please answer the following questions below on what you thought of
                                your manager and/or the company as a whole:
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        {ManagerCompany.map((item) =>
                            <>

                                <Col sm={12} md={6} className="pb-5">
                                    <label className="text-cerulean mb-3">
                                        {item.question}
                                    </label>
                                    <div className="mb-1 row">
                                        <label className="col-sm-4 col-form-label text-cerulean">
                                            Manager
                                        </label>
                                        <label className="col-sm-8 col-form-label">
                                            {getValues(item.id_manager)}
                                        </label>
                                    </div>
                                    <div className="mb-1 row">
                                        <label className="col-sm-4 col-form-label text-cerulean">
                                            The Company
                                        </label>

                                        <label className="col-sm-8 col-form-label">
                                            {getValues(item.id_company)}
                                        </label>
                                    </div>
                                    <div className="mb-1 row">
                                        <label className="col-form-label text-cerulean pb-2">
                                            Comments
                                        </label>
                                        <p className="col-sm-8 col-form-label">
                                            {getValues(item.id_comments)}
                                        </p>
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </Container>
                <Container>
                    <Row className="mb-5">
                        <Col>
                            <h2 className="text-cerulean">Please rate the following:</h2>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        {Ratings.map((item) =>

                            <Col sm={12} md={6} className="mb-5">
                                <p className="text-cerulean">{item.question}</p>
                                <label>{getValues(item.id)}</label>

                            </Col>
                        )}
                    </Row>

                </Container>
                <Container>
                    <Row className="mt-4">
                        {MultiSelect2.map((item) =>
                            <Col sm={12}>
                                <div className="form-group row  pb-3">
                                    <p className="text-cerulean">
                                        {item.question}
                                    </p>
                                    {item.type === 'checkbox' ?
                                        parseArray(item.id)
                                        :
                                        <label>{getValues(item.id)}</label>
                                    }
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
                <Container>
                    <Row className="pb-5">
                        {Performance.map((item) =>
                            <Col sm={12} md={6} className="pb-5">
                                <p className="text-cerulean">{item.question}</p>
                                <label>{getValues(item.id)}</label>
                            </Col>
                        )}
                    </Row>
                </Container>
                <Container>
                    <Row>
                        {LongFormQuestions.map((item) =>
                            <Col sm={12} className="pb-5">
                                <p className="text-cerulean pb-1">
                                    {item.question.replace("|theCompany|", targetUser.division)}
                                </p>
                                <label>{getValues(item.id)}</label>
                            </Col>
                        )}
                    </Row>
                </Container> 
                </div>
            </div>
            {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && 
                <button className="btn btn-cerulean" onClick={onPreviewPDF}>Preview PDF</button> 
            } 
        </>
    )
}
export default PDFOutput; 