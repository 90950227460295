export interface IFormInput {
    region: String | any; 
    currentUser: String | any;
    targetUser: String | any;
    targetUserID: String | any;
    rfl_q1: String;
    rfl_q2: String;
    rfl_q3: String;
    rfl_MultiSelect: string[];
    rfl_MultiSelectOther: String;
    rfl_q4: String;
    rfl_q5: String;
    mc_q1: String;
    mc_q2: String;
    mc_q3: String;
    mc_q4: String;
    mc_q5: String;
    mc_q6: String;
    mc_q7: String;
    mc_q8: String;
    mc_q9: String;
    mc_q10: String;
    mc_q11: String;
    mc_q12: String;
    mc_q13: String;
    mc_q14: String;
    mc_q15: String;
    mc_q16: String;
    mc_q17: String;
    mc_q18: String;
    rating_q1: String;
    rating_q2: String;
    rating_q3: String;
    rating_q4: String;
    rating_q5: String;
    rating_q6: String;
    rating_q7: String;
    rating_q8: String;
    rating_q9: String;
    rating_q10: String;
    rating_q11: String;
    rating_q12: String;
    rating_q13: String;
    rating_q14: String;
    rating_q15: String;
    rating_q16: String;
    rating_q17: String;
    rating_q18: String;
    rating_q19: String;
    rating_q20: String;
    perf_MultiSelect: string[];
    perf_MultiSelectOther: String;
    perf_q1: String;
    perf_q2: String;
    perf_q3: String;
    perf_q4: String;
    lf_q1: String;
    lf_q2: String;
    lf_q3: String;
    lf_q4: String;
    lf_q5: String;
    lf_q6: String;
    lf_q7: String;
    lf_q8: String;
    status: String;
    pdfInfo: String;
}
 
export interface Employee {
    id: string;
    employeeNumber?: string,  
    fullName1: string;
    division: string;
    jobTitle: string;
    location: string;
    supervisor: string;
    hireDate: Date;
    terminationDate: Date; 
    region: string
}
  
export const ReasonsForLeaving = [
    {
        id: "rfl_q1",
        type:"select", 
        question: "Please indicate reason for leaving |theCompany|:"
    },
    {
        id: "rfl_q2",
        type: "text", 
        question: "What is the name of your new employer?"
    },
    {
        id: "rfl_q3",
        type: "text", 
        question: 'If "Other", please explain:'
    },
    {
        id: "rfl_MultiSelect",
        type: "checkbox", 
        question: "What prompted you to seek alternative employment? (Select all that apply)"
    },
    {
        id: "rfl_MultiSelectOther",
        type: "textarea",
        question: 'If "Other", please explain what prompted you to seek alternative employment:'
    }, 
    {
        id: "rfl_q4",
        type: "select", 
        question: "Before making your decision to leave, did you explore other opportunities at |theCompany|?"

    },
    {
        id: "rfl_q5",
        type: "textarea", 
        question:
            "If yes, what other opportunities and/or options were offered? / If no, why did you not explore other opportunities?"
    }
]

export const ManagerCompany = [
    {
        question: "Demonstrated fair and equal treatment?",
        id_manager: "mc_q1",
        id_company: "mc_q2",
        id_comments: "mc_q13"
    },
    {
        question: "Provided recognition for work done well?",
        id_manager: "mc_q3",
        id_company: "mc_q4",
        id_comments: "mc_q14"
    },
    {
        question: "Fostered an inclusive environment?",
        id_manager: "mc_q5",
        id_company: "mc_q6",
        id_comments: "mc_q15"
    },
    {
        question: "Encouraged and listened to suggestions?",
        id_manager: "mc_q7",
        id_company: "mc_q8",
        id_comments: "mc_q16"
    },
    {
        question: "Followed policies and procedures?",
        id_manager: "mc_q9",
        id_company: "mc_q10",
        id_comments: "mc_q17"
    },
    {
        question: "Resolved complaints and problems?",
        id_manager: "mc_q11",
        id_company: "mc_q12",
        id_comments: "mc_q18"
    }
]

export const Ratings = [
    { id: "rating_q1", question: "Cooperation within your department?" },
    { id: "rating_q2", question: "Cooperation with other departments?" },
    { id: "rating_q3", question: "Communications in your department?" },
    { id: "rating_q4", question: "Communications within the company as a whole?" },
    { id: "rating_q5", question: "Communications between you and your line manager?" },
    { id: "rating_q18", question: "Comments:" },

    { id: "rating_q6", question: "The training you received/necessary tools to do your job?" },
    { id: "rating_q7", question: "Potential for career growth/development opportunities?" },
    { id: "rating_q8", question: "Opportunity for advancement?" },
    { id: "rating_q9", question: "Was your workload usually:" },
    { id: "rating_q19", question: "Comments:" },

    { id: "rating_q10", question: "Base salary:" },
    { id: "rating_q11", question: "401(k) program:" },
    { id: "rating_q12", question: "Bonus program:" },
    { id: "rating_q13", question: "Various insurance plans:" },
    { id: "rating_q14", question: "Paid time off:" },
    { id: "rating_q15", question: "Employee recognition programs:" },
    { id: "rating_q16", question: "Are there any other benefits you feel should have been offered?" },
    { id: "rating_q20", question: "Comments:" } 
]

export const MultiSelect2 = [
    {
        id: "perf_MultiSelect",
        type: "checkbox",
        question: "What were the most important factors when selecting your new job? (Select all that apply)"
    },
    {
        id: "perf_MultiSelectOther",
        type: "text",
        question: 'If "Other", please explain what were the most important factors when selecting your new job:'
    }
]

export const Performance = [
    { id: "perf_q1", question: "How frequently did you get performance feedback?" },
    { id: "perf_q2", question: "In your opinion, was the performance feedback sufficient?" },
    { id: "perf_q3", question: "How frequently did you have discussions with your manager about your career goals?" },
    { id: "perf_q4", question: "Comments:" }
];

export const LongFormQuestions = [
    { id: "lf_q1", question: "How would you describe |theCompany|'s company culture?" },
    { id: "lf_q2", question: "What did you like most about your job and/or |theCompany|?" },
    { id: "lf_q3", question: "What did you like least about your job and/or |theCompany|?" },
    { id: "lf_q4", question: "What does your new job offer that your job with |theCompany| did not?" },
    { id: "lf_q5", question: "Would you recommend working at |theCompany| to a friend?" },
    { id: "lf_q6", question: "Why or why not would you recommend working at |theCompany| to a friend?" },
    { id: "lf_q8", question: "Overall how would you rate |theCompany| as an employer?" },
    { id: "lf_q7", question: "Additional comments about your job or |theCompany|?" }
];
 
export const Removed = [ 
    { id: "rating_q17", question: "" }, 
]


