import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "react-bootstrap/Button";
import { UseFormWatch } from "react-hook-form";
import { IFormInput } from "../Interfaces/IFormInput";
import RatingsQuestions from "./RatingsQuestions";

interface RatingQuestionsProps {
  company: string | undefined;
    register: any;
    watch: UseFormWatch<IFormInput>
}
//Answers arrays for drop downs
const EGFP = ["Excellent", "Good", "Fair", "Poor"]
const GRLV = ["Too Great", "About Right", "Too Light", "Varied, but Manageable"];

function RatingQuestions({ watch, register }: RatingQuestionsProps) {
   
  return (
    <Container>
      <Row className="mb-5">
        <Col>
          <h2 className="text-cerulean">Please rate the following:</h2>
        </Col>
      </Row>

      <Row className="mb-1">
        <Col>
          <h3 className="text-prussian-blue py-1">
            Cooperation/Communications
          </h3>
        </Col>
      </Row>

      <Row className="mb-5">
      <RatingsQuestions register={register} answers={EGFP} title={'Cooperation within your department?'} questionID={'rating_q1'}/>
      <RatingsQuestions register={register} answers={EGFP} title={'Cooperation with other departments?'} questionID={'rating_q2'}/>
      </Row>

      <Row className="mb-5">
      <RatingsQuestions register={register} answers={EGFP} title={'Communications in your department?'} questionID={'rating_q3'}/>
      <RatingsQuestions register={register} answers={EGFP} title={'Communications within the company as a whole?'} questionID={'rating_q4'}/>
      </Row>

      <Row className="mb-5">
      <RatingsQuestions register={register} answers={EGFP} title={'Communications between you and your line manager?'} questionID={'rating_q5'}/>
        <Col sm={12} md={6}>
          <div></div>
        </Col>
        <Col>
          <label className="text-cerulean pt-5 pb-3">
            Comments:
          </label>
      
          <div className="col-sm-9">
            <textarea
              {...register("rating_q18")}
              className="form-control"
            >
            </textarea>
          </div>
          </Col>
      </Row>

      <Row className="mb-1">
        <Col>
          <h3 className="text-prussian-blue py-1">
            Training/Professional Development
          </h3>
        </Col>
      </Row>

      <Row className="mb-5">
      <RatingsQuestions register={register} answers={EGFP} title={'The training you received/necessary tools to do your job?'} questionID={'rating_q6'}/>
      <RatingsQuestions register={register} answers={EGFP} title={'Potential for career growth/development opportunities?'} questionID={'rating_q7'}/>
      </Row>

      <Row className="mb-5">
      <RatingsQuestions register={register} answers={EGFP} title={'Opportunity for advancement?'} questionID={'rating_q8'}/>
      <RatingsQuestions register={register} answers={GRLV} title={'Was your workload usually:'} questionID={'rating_q9'}/>
      <Col>
          <label className="text-cerulean pt-5 pb-3">
            Comments:
          </label>
      
          <div className="col-sm-9">
            <textarea
              {...register("rating_q19")}
              className="form-control"
            >
            </textarea>
          </div>
          </Col>
      </Row>

      <Row className="mb-1">
        <Col>
          <h3 className="text-prussian-blue py-1">
            Compensation/Benefits
          </h3>
        </Col>
      </Row>

      <Row className="mb-5">
      <RatingsQuestions register={register} answers={EGFP} title={'Base salary:'} questionID={'rating_q10'}/>
      <RatingsQuestions register={register} answers={EGFP} title={'401(k) program:'} questionID={'rating_q11'}/>
      </Row>

      <Row className="mb-5">
      <RatingsQuestions register={register} answers={EGFP} title={'Bonus program:'} questionID={'rating_q12'}/>
      <RatingsQuestions register={register} answers={EGFP} title={'Various insurance plans:'} questionID={'rating_q13'}/>
      </Row>

      <Row className="mb-5">
      <RatingsQuestions register={register} answers={EGFP} title={'Paid time off:'} questionID={'rating_q14'}/>
      <RatingsQuestions register={register} answers={EGFP} title={'Employee recognition programs:'} questionID={'rating_q15'}/>
      </Row>

      <Row className="mb-2">
        <Col sm={12} md={6}>
          <div>
            <p className="text-cerulean">
              Are there any other benefits you feel should have been offered?
            </p>
            <select
              className="form-select w-50"
              {...register("rating_q16")} 
            >
              <option hidden value="">
                Select One
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div></div>
        </Col>
      </Row>
      {watch("rating_q16")?.includes("Yes") && (
        <Row className="mb-2 mt-5">
          <Col>
            <div className="form-group">
              <p className="form-label text-cerulean">
                If yes, what other benefits and/or options were offered?
              </p>
              <textarea
                {...register("rating_q17")}
                rows={4}
                className="form-control w-75"
              ></textarea>
            </div>
          </Col>
        </Row>
      )}
      <Row>
      <Col className="mb-5" >
          <label className="text-cerulean pt-5 pb-3">
            Comments:
          </label>
      
          <div className="col-sm-9">
            <textarea
              {...register("rating_q20")}
              className="form-control"
            >
            </textarea>
          </div>
          </Col>
      </Row>
    </Container>
  );
}

export default RatingQuestions;
