import * as React from "react";
import { useEffect, useState, createContext, ReactNode } from "react";
import { useOktaAuth } from "@okta/okta-react/";
import { Spinner } from "react-bootstrap"; 


export interface LoggedInUser {
    name: string
    bamboohrId: string;
    Department?: string;
    Company?: string;
}

export let userContext = createContext<LoggedInUser | null>(null);

interface Props {
    children?: any
    // any props that come into the component
}

const UserContext = ({ children }: Props) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState<LoggedInUser | null>(null);


    const getUser = async () => {
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        }
        else {
            oktaAuth.getUser()
                .then((info: any) => {
                    setUserInfo(info);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        userContext = createContext(userInfo);
    }

    const login = async () => oktaAuth.signInWithRedirect();

    //useEffect(() => {
    //    getUser()
    //        .catch((e) => console.log(e));
    //}, [authState, oktaAuth]);

     

    useEffect(() => {
        const isAuthenticated = authState?.isAuthenticated;  
        console.log(isAuthenticated); 
        if (!authState || !authState.isAuthenticated) { 
            setUserInfo(null);
            //login(); 
        }
        else {
            oktaAuth.getUser()
                .then((info: any) => { 
                    setUserInfo(info);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        userContext = createContext(userInfo);
    }, [authState, oktaAuth]);


                //:
                //<div className="d-flex justify-content-center vh-100">
                //    <div className="align-self-center">
                //        <Spinner animation="border" role="status">
                //            <span className="visually-hidden">Loading...</span>
                //        </Spinner>
                //    </div>
                //</div>

    return (
        <>
             
                <userContext.Provider value={userInfo}> 
                    <>{children}</>
                </userContext.Provider>
                
             
        </>
    )
}
export default UserContext; 