import "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import "../App.css";

interface LongFormQuestionsProps {
    company: string | undefined;
    register: any;
}

function LongFormQuestions({ company, register }: LongFormQuestionsProps) {
    return (
        <Container>
            <Row>
                <Col>
                    <div className="pb-3">
                        <p className="text-cerulean pb-1">
                            How would you describe {company}'s company culture?
                        </p>
                        <textarea
                            {...register("lf_q1")}
                            rows={3}
                            cols={160}
                            className="form-control w-75"
                        ></textarea>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className="py-3">
                        <p className="text-cerulean pb-1">
                            What did you like most about your job and/or {company}?
                        </p>
                        <textarea
                            {...register("lf_q2")}
                            rows={3}
                            cols={160}
                            className="form-control w-75"
                        ></textarea>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="py-3">
                        <p className="text-cerulean pb-1">
                            What did you like least about your job and/or {company}?
                        </p>
                        <textarea
                            {...register("lf_q3")}
                            rows={3}
                            cols={160}
                            className="form-control w-75"
                        ></textarea>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="py-3 ">
                        <p className="text-cerulean pb-1">
                            What does your new job offer that your job with {company} did not?
                        </p>

                        <textarea
                            {...register("lf_q4")}
                            rows={3}
                            cols={160}
                            className="form-control w-75"
                        ></textarea>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <div className="py-3">
                        <p className="text-cerulean pb-1">
                            Would you recommend working at {company} to a friend?
                        </p>
                        <select className="form-select w-50" {...register("lf_q5")}>
                            <option hidden value="">
                                Select One
                            </option>
                            <option value="Yes, without reservations">
                                Yes, without reservations
                            </option>
                            <option value="Yes, with reservations">
                                Yes, with reservations
                            </option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="py-4">
                        <p className="text-cerulean pb-1">Why or why not would you recommend working at {company} to a friend?</p>
                        <textarea
                            {...register("lf_q6")}
                            rows={3}
                            cols={160}
                            className="form-control w-75"
                        ></textarea>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <div className="py-3">
                        <p className="text-cerulean pb-1">
                            Overall how would you rate {company} as an employer?
                        </p>
                        <select className="form-select w-50" {...register("lf_q8")}>
                            <option hidden value="">
                                Select One
                            </option>
                            <option value="5 - Excellent">5 - Excellent</option>
                            <option value="4 - Very good">4 - Very good</option>
                            <option value="3 - Good">3 - Good</option>
                            <option value="2 - Fair">2 - Fair</option>
                            <option value="1 - Poor">1 - Poor</option>
                        </select>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <p className="text-cerulean pt-4 pb-1">
                            Additional comments about your job or {company}?
                        </p>
                        <textarea
                            {...register("lf_q7")}
                            rows={3}
                            cols={160}
                            className="form-control w-75"
                        ></textarea>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default LongFormQuestions;
