import { useEffect, useState } from "react";
import * as React from "react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { MultiColumnComboBox, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import '@progress/kendo-theme-bootstrap/dist/all.css';
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
 
interface ShowSearchProps { 
    onChange: (e: any) => void
}
const columns = [
     
    { field: "employeeNumber", header: "Employee #", width: "150px", uniqueKey:"id" }, 
    { field: "fullName1", header: "Name", width: "300px" }
];

const ShowSearch = ({ onChange }: ShowSearchProps) => {

    const { oktaAuth } = useOktaAuth();
    const termurl = process.env.REACT_APP_ENDPOINT_URL + `/api/bamboo`;//Getting target user
    const [terminatedUsersList, setTerminatedUsersList] = useState([]);
     
    //Grabbing Users scheduled to be terminated in Bamboo
    useEffect(() => {
        const fetchData = async () => {
            try {
                const accessToken = oktaAuth.getAccessToken();
                const result = await axios.get(termurl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                });
                setTerminatedUsersList(result.data); 

            } catch (error) {
                console.log(error);
            }
        };

        fetchData();

    }, []);

    const [filter, setFilter] = React.useState<FilterDescriptor>();

    const handleFilterChange = (event: ComboBoxFilterChangeEvent) => {
        if (event) {
            setFilter(event.filter);
        }
    };  

    return (
        <><label className="nw-label text-cerulean py-2">
            If you are filling out this form on behalf of a North Wind Employee,
            type the employee’s name in the field below to populate their
            information.
        </label>
            <div className="col-xs-12 col-sm-7 example-col pb-3">
                <MultiColumnComboBox
                    style={{ width: "300px" }}
                    data={filter ? filterBy(terminatedUsersList, filter) : terminatedUsersList}
                    filterable={true}
                    columns={columns}
                    onChange={onChange}
                    onFilterChange={handleFilterChange}
                    textField={"fullName1"} 
                    
                />
        </div></>
    );
};

export default ShowSearch;


