import "react-bootstrap/Button";
import { Col, Container, Row } from "react-bootstrap";
import "../App.css";
import { useState } from "react";
import { IFormInput } from "../Interfaces/IFormInput";
import { UseFormWatch } from "react-hook-form";
//import { useForm, UseFormWatch } from "react-hook-form";

interface ReasonsForLeavingProps {
    company: string | undefined;
    register: any;
    watch: UseFormWatch<IFormInput>
}
const YN = [
    'Yes',
    'No'
]

const multiSelect = [
    "Type Of Work",
    "Quality Of Supervision",
    "Health Reasons",
    "Compensation",
    "Work Conditions",
    "Career Opportunity",
    "Self Employment",
    "Family Circumstances",
];
//Also pass it into the function like so...
function ReasonsForLeaving({ company, register, watch }: ReasonsForLeavingProps) { 
      
    
    const YesNoAnswers = YN.map((item: string) => (
        <option className="dropdown-value" key={item} value={item}>
            {item}
        </option>
    ));

    
    const selectItems = multiSelect.map((item, index) => (
        <div className="nw-checkbox" key={index}>
            <div className="form-check">
                <input key={index}
                    className="form-check-input"
                    {...register("rfl_MultiSelect")}
                    name="rfl_MultiSelect"
                    value={item}
                    type="checkbox"
                    id={item} 
                />
                <label className="form-check-label d-block" htmlFor={item}>
                    {item}
                </label>
            </div>
        </div>
    ));

    return (
        <Container className="reasonsForLeaving">
            <Row className="mb-2">
                <Col sm={12} md={6}>
                    <div className="form-group mb-5">
                        <p className="text-cerulean">
                            Please indicate reason for leaving {company}:
                        </p>
                        <select
                            className="reason-for-leaving form-select w-50 "
                            {...register("rfl_q1")} 
                            name={"rfl_q1"} 
                        >
                            <option hidden value="">
                                Select One
                            </option>
                            <option key="Retirement" value="Retirement">Retirement</option>
                            <option key="Leaving the workforce" value="Leaving the workforce">Leaving the workforce</option>
                            <option key="Changing industries" value="Changing industries">Changing industries</option>
                            <option key="Changing jobs within the same industry" value="Changing jobs within the same industry">Changing jobs within the same industry</option>
                            <option key="Pursuing additional education/reskilling" value="Pursuing additional education/reskilling">Pursuing additional education/reskilling</option>
                            <option key="Other" value="Other">Other</option>
                        </select>
                    </div>
                </Col>
                <Col sm={12} md={6}>
                    <div className="form-group mb-5" style={{ textAlign: "left" }}>
                        <p className=" text-cerulean">
                            What is the name of your new employer?
                        </p>
                        <input
                            {...register("rfl_q2")}
                            className="form-control w-50"
                            type={"text"}
                        ></input>
                    </div>
                </Col>
            </Row>

            {watch("rfl_q1")?.includes("Other") && (
                <Row className="mb-5">
                    <Col sm={12}>
                        <div className="form-group">
                            <p className=" text-cerulean">If "Other", please explain:</p>
                            <textarea
                                {...register("rfl_q3")}
                                className="form-control w-75"
                            ></textarea>
                        </div>
                    </Col>
                </Row>
            )}

            <Row className="mb-4">
                <Col sm={12}>
                    <div className="form-group row">
                        <p className=" text-cerulean">
                            What prompted you to seek alternative employment? (Select all that
                            apply)
                        </p>
                    </div>

                    {selectItems}

                    <div className="nw-checkbox">
                        <div className="form-check">
                            <input key={99}
                                {...register("rfl_MultiSelect")} 
                                className="form-check-input"
                                type="checkbox"
                                id="Other1"
                                name="rfl_MultiSelect"
                                value="Other" 
                                
                            />
                            <label className="form-check-label d-block" htmlFor="Other1">
                                Other
                            </label>
                        </div>
                    </div>
                </Col>
            </Row>

            {watch("rfl_MultiSelect")?.includes("Other") && (
                <Row className="mb-5">
                    <Col sm={12}>
                        <div className="form-group">
                            <p className="text-cerulean">If "Other", please explain what prompted you to seek alternative employment:</p>
                            <textarea
                                {...register("rfl_MultiSelectOther")}
                                className="form-control w-75"
                            ></textarea>
                        </div>
                    </Col>
                </Row>
            )}

            <Row className="mb-1">
                <Col>
                    <div className="form-group pb-5">
                        <p className=" text-cerulean">
                            Before making your decision to leave, did you explore other
                            opportunities at {company}?
                        </p>
                        <select className="form-select w-50"
                            {...register("rfl_q4")} >
                            <option hidden value="">
                                Select One
                            </option>
                            {YesNoAnswers}
                        </select>
                    </div>
                </Col>
                <Col></Col>
            </Row>

            { watch("rfl_q4")?.includes("Yes") && (
                <Row className="mb-5">
                    <Col>
                        <div className="form-group">
                            <p className="form-label text-cerulean">
                                If yes, what other opportunities and/or options were offered?
                            </p>
                            <textarea
                                {...register("rfl_q5")}
                                rows={4}
                                className="form-control w-75"
                            ></textarea>
                        </div>
                    </Col>
                </Row>)}
            {watch("rfl_q4")?.includes("No") && (
                <Row className="mb-5">
                    <Col>
                        <div className="form-group">
                            <p className="form-label text-cerulean">
                                If no, why did you not explore other opportunities?
                            </p>
                            <textarea
                                {...register("rfl_q5")}
                                rows={4}
                                className="form-control w-75"
                            ></textarea>
                        </div>
                    </Col>
                </Row>)}
        </Container>
    );
}

export default ReasonsForLeaving;
