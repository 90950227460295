import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { UseFormWatch } from "react-hook-form";
import { IFormInput } from "../Interfaces/IFormInput";

interface MultiSelect2Props {
    company: string | undefined;
    register: any;
    watch: UseFormWatch<IFormInput>;
}
const multiSelect = [
    "Pay",
    "Paid Time Off",
    "Work Schedule",
    "Flexibility",
    "Culture",
    "Benefits",
];

export default function MultiSelect2({ register, watch }: MultiSelect2Props) {
    const [showperfOtherOnClick, setperfOtherOnClick] = useState(false);
    const handleClick = () => setperfOtherOnClick(!showperfOtherOnClick); 
    
    const selectItems = multiSelect.map((item, index) => (
        <div className="nw-checkbox" key={index}>
            <div className="form-check">
                <input key={index}
                    className="form-check-input"
                    {...register("perf_MultiSelect")}
                    name="perf_MultiSelect"
                    value={item}
                    type="checkbox"
                    id={item}
                />
                <label className="form-check-label d-block" htmlFor={item}>
                    {item}
                </label>
            </div>
        </div>
    ));

    return (
        <Container className="mt-5">
            <Row className="mt-4">
                <Col sm={12}>
                    <div className="form-group row">
                        <p className="text-cerulean pb-3">
                            What were the most important factors when selecting your new job?
                            (Select all that apply)
                        </p>
                    </div>

                    {selectItems}
                    <div className="nw-checkbox">
                        <div className="form-check">
                            <input key={99}
                                {...register("perf_MultiSelect")}
                                className="form-check-input"
                                type="checkbox"
                                id="Other2"
                                name="perf_MultiSelect"
                                value="Other"
                             />
                            <label className="form-check-label d-block" htmlFor="Other2">
                                Other
                            </label>
                        </div>
                    </div>
                </Col>
            </Row>
            {watch("perf_MultiSelect")?.includes("Other") && (
                <Row className="mb-5">
                    <Col sm={12}>
                        <div className="form-group">
                            <p className="text-cerulean">If "Other", please explain what were the most important factors when selecting your new job:</p>
                            <textarea
                                {...register("perf_MultiSelectOther")}
                                className="form-control w-75"
                            ></textarea>
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
}
