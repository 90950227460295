import { Col } from 'react-bootstrap';


interface RatingsQuestionsProps {
    register: any;
    answers: any;
    title: string;
    questionID: string;
  }

function RatingQuestions({register, answers, title, questionID}: RatingsQuestionsProps){
        const ratingAnswers = answers.map((item: string) => (
          <option className="dropdown-value" key= {item} value={item}>
            {item}
          </option>
        ));
        return (
          <Col sm={12} md={6}>
            <p className="text-cerulean">{title}</p>
            <select className="form-select w-50" {...register(questionID)}>
              <option hidden value="">
                Select One
              </option>
              {ratingAnswers}
            </select>
          </Col>
        );
}


export default RatingQuestions;
