import React from 'react';
import { Switch, Route, useHistory } from "react-router-dom";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "./oktaConfig";
import Home from "./Pages/Home";
import AccessDenied from "./Pages/AccessDenied";
import ThankYou from "./Pages/ThankYou";
import { Alert } from 'react-bootstrap';
import UserContext from './Components/context/UserContext';

const oktaAuth = new OktaAuth(config.oidc);

function App() {   
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    };
    const handleClick1 = () => history.push("/thankyou");
    const handleClick2 = () => history.push("/accessdenied");
    const sendToHome = () => history.push("/");


    //const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);
    //const triggerLogin = async () => {
    //    await oktaAuth.signInWithRedirect();
    //};

    //const customAuthHandler = async () => {
    //    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    //    if (!previousAuthState || !previousAuthState.isAuthenticated) {
    //        // App initialization stage 
    //        await triggerLogin();
    //    } else {
    //        // Ask the user to trigger the login process during token autoRenew process
    //        setAuthRequiredModalOpen(true);
    //    }
    //};

    return (
        <div className="App">  
            {window.location.hostname === 'nw-eiq-webui.azurewebsites.net' || window.location.hostname === 'localhost' &&
                (
                    <Alert color="danger" className="m-0">
                        <div
                            className="d-flex justify-content-around p-1">
                            <button onClick={sendToHome} className="btn btn-prussian-blue text-white"  >
                                Home
                            </button>
                            <button onClick={handleClick2} className="btn btn-ghost">
                                Access Denied
                            </button>
                            <button onClick={handleClick1} className="btn btn-cerulean">
                                Thank You
                            </button>
                        </div>
                    </Alert>
                )
            }
            <Security
                oktaAuth={oktaAuth}
                //onAuthRequired={customAuthHandler}
                restoreOriginalUri={restoreOriginalUri} 
            > 
                <Switch>
                    <> 
                        <Route exact path="/login/callback" component={LoginCallback} /> 
                        <UserContext>
                            <SecureRoute exact path="/" component={Home} />
                            <SecureRoute exact path="/accessdenied" component={AccessDenied} />
                            <SecureRoute exact path="/thankyou" component={ThankYou} />
                        </UserContext> 
                    </>
                </Switch>
            </Security>
        </div>
    );
}
export default App;



